export const myanmarstate = [
  {
    name: "ကချင်ပြည်နယ်",
    townships: [
      {
        township: "Hpakant",
        township_mm: "ဖားကန့်",
      },
      {
        township: "Shwegu",
        township_mm: "ရွှေကူ",
      },
      {
        township: "Mohnyin",
        township_mm: "မိုးညှင်း",
      },
      {
        township: "Tanai",
        township_mm: "တနိုင်း",
      },
      {
        township: "Mogaung",
        township_mm: "မိုးကောင်း",
      },
      {
        township: "Momauk",
        township_mm: "မိုးမောက်",
      },
      {
        township: "Nawngmun",
        township_mm: "နောင်မွန်း",
      },
      {
        township: "Myitkyina",
        township_mm: "မြစ်ကြီးနား",
      },
      {
        township: "Injangyang",
        township_mm: "အင်ဂျန်းယန်",
      },
      {
        township: "Mansi",
        township_mm: "မန်စီ",
      },
      {
        township: "Sumprabum",
        township_mm: "ဆွမ်ပရာဘွမ်",
      },
      {
        township: "Khaunglanhpu",
        township_mm: "ခေါင်လန်ဖူး",
      },
      {
        township: "Chipwi",
        township_mm: "ချီဗွေ",
      },
      {
        township: "Puta-O",
        township_mm: "ပူတာအို",
      },
      {
        township: "Waingmaw",
        township_mm: "ဝိုင်းမော်",
      },
      {
        township: "Tsawlaw",
        township_mm: "ဆော့လော်",
      },
      {
        township: "Machanbaw",
        township_mm: "မချမ်းဘော",
      },
      {
        township: "Bhamo",
        township_mm: "ဗန်းမော်",
      },
    ],
  },
  {
    name: "ရှမ်းပြည်နယ်",
    townships: [
      {
        township: "Mongmit",
        township_mm: "မိုးမိတ်",
      },
      {
        township: "Pindaya",
        township_mm: "ပင်းတယ",
      },
      {
        township: "Ywangan",
        township_mm: "ရွာငံ",
      },
      {
        township: "Pinlaung",
        township_mm: "ပင်လောင်း",
      },
      {
        township: "Kalaw",
        township_mm: "ကလော",
      },
      {
        township: "Pekon",
        township_mm: "ဖယ်ခုံ",
      },
      {
        township: "Lawksawk",
        township_mm: "ရပ်စောက်",
      },
      {
        township: "Laihka",
        township_mm: "လဲချား",
      },
      {
        township: "Mongnai",
        township_mm: "မိုးနဲ",
      },
      {
        township: "Mawkmai",
        township_mm: "မောက်မယ်",
      },
      {
        township: "Mongkaing",
        township_mm: "မိုင်းကိုင်",
      },
      {
        township: "Mongpan",
        township_mm: "မိုင်းပန်",
      },
      {
        township: "Nansang",
        township_mm: "နမ့်စန်",
      },
      {
        township: "Hopong",
        township_mm: "ဟိုပုံး",
      },
      {
        township: "Nyaungshwe",
        township_mm: "ညောင်ရွှေ",
      },
      {
        township: "Hsihseng",
        township_mm: "ဆီဆိုင်",
      },
      {
        township: "Langkho",
        township_mm: "လင်းခေး",
      },
      {
        township: "Monghsu",
        township_mm: "မိုင်းရှုး",
      },
      {
        township: "Taunggyi",
        township_mm: "တောင်ကြီး",
      },
      {
        township: "Kyethi",
        township_mm: "ကျေးသီး",
      },
      {
        township: "Loilen",
        township_mm: "လွိုင်လင်",
      },
      {
        township: "Kunhing",
        township_mm: "ကွန်ဟိန်း",
      },
      {
        township: "Mabein",
        township_mm: "မဘိမ်း",
      },
      {
        township: "Nawnghkio",
        township_mm: "နောင်ချို",
      },
      {
        township: "Kyaukme",
        township_mm: "ကျောက်မဲ",
      },
      {
        township: "Muse",
        township_mm: "မူဆယ်",
      },
      {
        township: "Kutkai",
        township_mm: "ကွတ်ခိုင်",
      },
      {
        township: "Mongyai",
        township_mm: "မိုင်းရယ်",
      },
      {
        township: "Lashio",
        township_mm: "လားရှိုး",
      },
      {
        township: "Matman",
        township_mm: "မက်မန်း",
      },
      {
        township: "Narphan",
        township_mm: "နားဖန့်",
      },
      {
        township: "Hsipaw",
        township_mm: "သီပေါ",
      },
      {
        township: "Mongmao",
        township_mm: "မိုင်းမော",
      },
      {
        township: "Laukkaing",
        township_mm: "လောက်ကိုင်",
      },
      {
        township: "Pangsang",
        township_mm: "ပန်ဆန်း",
      },
      {
        township: "Namtu",
        township_mm: "နမ္မတူ",
      },
      {
        township: "Konkyan",
        township_mm: "ကုန်းကြမ်း",
      },
      {
        township: "Hseni",
        township_mm: "သိန်းနီ",
      },
      {
        township: "Kunlong",
        township_mm: "ကွမ်းလုံ",
      },
      {
        township: "Hopang",
        township_mm: "ဟိုပန်",
      },
      {
        township: "Namhkan",
        township_mm: "နမ့်ခမ်း",
      },
      {
        township: "Pangwaun",
        township_mm: "ပန်ဝိုင်",
      },
      {
        township: "Manton",
        township_mm: "မန်တုန်",
      },
      {
        township: "Tangyan",
        township_mm: "တန့်ယန်း",
      },
      {
        township: "Namhsan",
        township_mm: "နမ့်ဆန်",
      },
      {
        township: "Mongton",
        township_mm: "မိုင်းတုံ",
      },
      {
        township: "Tachileik",
        township_mm: "တာချီလိတ်",
      },
      {
        township: "Mongkhet",
        township_mm: "မိုင်းခတ်",
      },
      {
        township: "Monghsat",
        township_mm: "မိုင်းဆတ်",
      },
      {
        township: "Monghpyak",
        township_mm: "မိုင်းဖြတ်",
      },
      {
        township: "Mongping",
        township_mm: "မိုင်းပျင်း",
      },
      {
        township: "Mongla",
        township_mm: "မိုင်းလား",
      },
      {
        township: "Kengtung",
        township_mm: "ကျိုင်းတုံ",
      },
      {
        township: "Mongyang",
        township_mm: "မိုင်းယန်း",
      },
      {
        township: "Mongyawng",
        township_mm: "မိုင်းယောင်း",
      },
    ],
  },
  {
    name: "ပဲခူးတိုင်းဒေသကြီး",
    townships: [
      {
        township: "Waw",
        township_mm: "ဝေါ",
      },
      {
        township: "Kyaukkyi",
        township_mm: "ကျောက်ကြီး",
      },
      {
        township: "Yedashe",
        township_mm: "ရေတာရှည်",
      },
      {
        township: "Taungoo",
        township_mm: "တောင်ငူ",
      },
      {
        township: "Nyaunglebin",
        township_mm: "ညောင်လေးပင်",
      },
      {
        township: "Phyu",
        township_mm: "ဖြူး",
      },
      {
        township: "Thanatpin",
        township_mm: "သနပ်ပင်",
      },
      {
        township: "Kyauktaga",
        township_mm: "ကျောက်တံခါး",
      },
      {
        township: "Daik-U",
        township_mm: "ဒိုက်ဦး",
      },
      {
        township: "Kawa",
        township_mm: "ကဝ",
      },
      {
        township: "Htantabin",
        township_mm: "ထန်းတပင်",
      },
      {
        township: "Oktwin",
        township_mm: "အုတ်တွင်း",
      },
      {
        township: "Bago",
        township_mm: "ပဲခူး",
      },
      {
        township: "Shwegyin",
        township_mm: "ရွှေကျင်",
      },
      {
        township: "Paukkhaung",
        township_mm: "ပေါက်ခေါင်း",
      },
      {
        township: "Minhla",
        township_mm: "မင်းလှ",
      },
      {
        township: "Pyay",
        township_mm: "ပြည်",
      },
      {
        township: "Padaung",
        township_mm: "ပန်းတောင်း",
      },
      {
        township: "Letpadan",
        township_mm: "လက်ပံတန်း",
      },
      {
        township: "Gyobingauk",
        township_mm: "ကြို့ပင်ကောက်",
      },
      {
        township: "Paungde",
        township_mm: "ပေါင်းတည်",
      },
      {
        township: "Okpho",
        township_mm: "အုတ်ဖို",
      },
      {
        township: "Thayarwady",
        township_mm: "သာယာဝတီ",
      },
      {
        township: "Monyo",
        township_mm: "မိုးညို",
      },
      {
        township: "Shwedaung",
        township_mm: "ရွှေတောင်",
      },
      {
        township: "Nattalin",
        township_mm: "နတ္တလင်း",
      },
      {
        township: "Zigon",
        township_mm: "ဇီးကုန်း",
      },
      {
        township: "Thegon",
        township_mm: "သဲကုန်း",
      },
    ],
  },
  {
    name: "မန္တလေးတိုင်းဒေသကြီး",
    townships: [
      {
        township: "Mogoke",
        township_mm: "မိုးကုတ်",
      },
      {
        township: "Pyinoolwin",
        township_mm: "ပြင်ဦးလွင်",
      },
      {
        township: "Kyaukse",
        township_mm: "ကျောက်ဆည်",
      },
      {
        township: "Chanmyathazi",
        township_mm: "ချမ်းမြသာစည်",
      },
      {
        township: "Chanayethazan",
        township_mm: "ချမ်းအေးသာဇံ",
      },
      {
        township: "Aungmyaythazan",
        township_mm: "အောင်မြေသာဇံ",
      },
      {
        township: "Myittha",
        township_mm: "မြစ်သား",
      },
      {
        township: "Tada-U",
        township_mm: "တံတားဦး",
      },
      {
        township: "Madaya",
        township_mm: "မတ္တရာ",
      },
      {
        township: "Singu",
        township_mm: "စဉ့်ကူး",
      },
      {
        township: "Myingyan",
        township_mm: "မြင်းခြံ",
      },
      {
        township: "Yamethin",
        township_mm: "ရမည်းသင်း",
      },
      {
        township: "Thabeikkyin",
        township_mm: "သပိတ်ကျဉ်း",
      },
      {
        township: "Mahaaungmyay",
        township_mm: "မဟာအောင်မြေ",
      },
      {
        township: "Wundwin",
        township_mm: "ဝမ်းတွင်း",
      },
      {
        township: "Pyawbwe",
        township_mm: "ပျော်ဘွယ်",
      },
      {
        township: "Ngazun",
        township_mm: "ငါန်းဇွန်",
      },
      {
        township: "Amarapura",
        township_mm: "အမရပူရ",
      },
      {
        township: "Kyaukpadaung",
        township_mm: "ကျောက်ပန်းတောင်း",
      },
      {
        township: "Sintgaing",
        township_mm: "စဉ့်ကိုင်",
      },
      {
        township: "Patheingyi",
        township_mm: "ပုသိမ်ကြီး",
      },
      {
        township: "Thazi",
        township_mm: "သာစည်",
      },
      {
        township: "Nyaung-U",
        township_mm: "ညောင်ဦး",
      },
      {
        township: "Natogyi",
        township_mm: "နွားထိုးကြီး",
      },
      {
        township: "Mahlaing",
        township_mm: "မလှိုင်",
      },
      {
        township: "Pyigyitagon",
        township_mm: "ပြည်ကြီးတံခွန်",
      },
      {
        township: "Meiktila",
        township_mm: "မိတ္ထီလာ",
      },
      {
        township: "Taungtha",
        township_mm: "တောင်သာ",
      },
    ],
  },
  {
    name: "ရန်ကုန်တိုင်းဒေသကြီး",
    townships: [
      {
        township: "Thongwa",
        township_mm: "သုံးခွ",
      },
      {
        township: "Kyauktan",
        township_mm: "ကျောက်တန်း",
      },
      {
        township: "Insein",
        township_mm: "အင်းစိန်",
      },
      {
        township: "Hmawbi",
        township_mm: "မှော်ဘီ",
      },
      {
        township: "Kayan",
        township_mm: "ခရမ်း",
      },
      {
        township: "Dagon Myothit (North)",
        township_mm: "ဒဂုံမြို့သစ်မြောက်ပိုင်း",
      },
      {
        township: "Pazundaung",
        township_mm: "ပုဇွန်တောင်",
      },
      {
        township: "Kungyangon",
        township_mm: "ကွမ်းခြံကုန်း",
      },
      {
        township: "Kyauktada",
        township_mm: "ကျောက်တံတား",
      },
      {
        township: "Lanmadaw",
        township_mm: "လမ်းမတော်",
      },
      {
        township: "Hlegu",
        township_mm: "လှည်းကူး",
      },
      {
        township: "Htantabin",
        township_mm: "ထန်းတပင်",
      },
      {
        township: "Botahtaung",
        township_mm: "ဗိုလ်တထောင်",
      },
      {
        township: "Mayangone",
        township_mm: "မရမ်းကုန်း",
      },
      {
        township: "Kyeemyindaing",
        township_mm: "ကြည့်မြင်တိုင်",
      },
      {
        township: "Latha",
        township_mm: "လသာ",
      },
      {
        township: "Mingalartaungnyunt",
        township_mm: "မင်္ဂလာတောင်ညွန်",
      },
      {
        township: "Tamwe",
        township_mm: "တာမွေ",
      },
      {
        township: "Thaketa",
        township_mm: "သာကေတ",
      },
      {
        township: "Dagon Myothit (East)",
        township_mm: "ဒဂုံမြို့သစ်အရှေ့ပိုင်း",
      },
      {
        township: "Ahlone",
        township_mm: "အလုံ",
      },
      {
        township: "Shwepyithar",
        township_mm: "ရွှေပြည်သာ",
      },
      {
        township: "Sanchaung",
        township_mm: "စမ်းချောင်း",
      },
      {
        township: "Kawhmu",
        township_mm: "ကော့မှုး",
      },
      {
        township: "North Okkalapa",
        township_mm: "မြောက်ဥက္ကလာပ",
      },
      {
        township: "Cocokyun",
        township_mm: "ကိုကိုးကျွန်း",
      },
      {
        township: "Yankin",
        township_mm: "ရန်ကင်း",
      },
      {
        township: "Thanlyin",
        township_mm: "သန်လျှင်",
      },
      {
        township: "Mingaladon",
        township_mm: "မင်္ဂလာဒုံ",
      },
      {
        township: "Dagon Myothit (South)",
        township_mm: "ဒဂုံမြို့သစ်တောင်ပိုင်း",
      },
      {
        township: "Kamaryut",
        township_mm: "ကမာရွတ်",
      },
      {
        township: "Thingangyun",
        township_mm: "သင်္ဃန်းကျွန်း",
      },
      {
        township: "Bahan",
        township_mm: "ဗဟန်း",
      },
      {
        township: "Dawbon",
        township_mm: "ဒေါပုံ",
      },
      {
        township: "Pabedan",
        township_mm: "ပန်းပဲတန်း",
      },
      {
        township: "Twantay",
        township_mm: "တွံတေး",
      },
      {
        township: "Seikgyikanaungto",
        township_mm: "ဆိပ်ကြီးခနောင်တို",
      },
      {
        township: "Dagon",
        township_mm: "ဒဂုံ",
      },
      {
        township: "Seikkan",
        township_mm: "ဆိပ်ကမ်း",
      },
      {
        township: "South Okkalapa",
        township_mm: "တောင်ဥက္ကလာပ",
      },
      {
        township: "Taikkyi",
        township_mm: "တိုက်ကြီး",
      },
      {
        township: "Hlaing",
        township_mm: "လှိုင်",
      },
      {
        township: "Dala",
        township_mm: "ဒလ",
      },
      {
        township: "Hlaingtharya",
        township_mm: "လှိုင်သာယာ",
      },
      {
        township: "Dagon Myothit (Seikkan)",
        township_mm: "ဒဂုံမြို့သစ်ဆိပ်ကမ်း",
      },
    ],
  },
  {
    name: "ကရင်ပြည်နယ်",
    townships: [
      {
        township: "Thandaunggyi",
        township_mm: "သံတောင်ကြီး",
      },
      {
        township: "Hpapun",
        township_mm: "ဖာပွန်",
      },
      {
        township: "Kawkareik",
        township_mm: "ကော့ကရိတ်",
      },
      {
        township: "Hlaingbwe",
        township_mm: "လှိုင်းဘွဲ့",
      },
      {
        township: "Myawaddy",
        township_mm: "မြဝတီ",
      },
      {
        township: "Kyainseikgyi",
        township_mm: "ကြာအင်းဆိပ်ကြီး",
      },
      {
        township: "Hpa-An",
        township_mm: "ဘားအံ",
      },
    ],
  },
  {
    name: "မကွေးတိုင်းဒေသကြီး",
    townships: [
      {
        township: "Kamma",
        township_mm: "ကံမ",
      },
      {
        township: "Mindon",
        township_mm: "မင်းတုန်း",
      },
      {
        township: "Aunglan",
        township_mm: "အောင်လံ",
      },
      {
        township: "Natmauk",
        township_mm: "နတ်မောက်",
      },
      {
        township: "Myaing",
        township_mm: "မြိုင်",
      },
      {
        township: "Sidoktaya",
        township_mm: "စေတုတ္တရာ",
      },
      {
        township: "Thayet",
        township_mm: "သရက်",
      },
      {
        township: "Saw",
        township_mm: "ဆော",
      },
      {
        township: "Minhla",
        township_mm: "မင်းလှ",
      },
      {
        township: "Myothit",
        township_mm: "မြို့သစ်",
      },
      {
        township: "Sinbaungwe",
        township_mm: "ဆင်ပေါင်ဝဲ",
      },
      {
        township: "Yenangyaung",
        township_mm: "ရေနံချောင်း",
      },
      {
        township: "Taungdwingyi",
        township_mm: "တောင်တွင်းကြီး",
      },
      {
        township: "Magway",
        township_mm: "မကွေး",
      },
      {
        township: "Ngape",
        township_mm: "ငဖဲ",
      },
      {
        township: "Pauk",
        township_mm: "ပေါက်",
      },
      {
        township: "Yesagyo",
        township_mm: "ရေစကြို",
      },
      {
        township: "Seikphyu",
        township_mm: "ဆိပ်ဖြူ",
      },
      {
        township: "Gangaw",
        township_mm: "ဂန့်ဂေါ",
      },
      {
        township: "Salin",
        township_mm: "စလင်း",
      },
      {
        township: "Minbu",
        township_mm: "မင်းဘူး",
      },
      {
        township: "Pakokku",
        township_mm: "ပခုက္ကူ",
      },
      {
        township: "Tilin",
        township_mm: "ထီးလင်း",
      },
      {
        township: "Pwintbyu",
        township_mm: "ပွင့်ဖြူ",
      },
      {
        township: "Chauk",
        township_mm: "ချောက်",
      },
    ],
  },
  {
    name: "ဧရာဝတီတိုင်းဒေသကြီး",
    townships: [
      {
        township: "Labutta",
        township_mm: "လပွတ္တာ",
      },
      {
        township: "Thabaung",
        township_mm: "သာပေါင်း",
      },
      {
        township: "Ngapudaw",
        township_mm: "ငပုတော",
      },
      {
        township: "Bogale",
        township_mm: "ဘိုကလေး",
      },
      {
        township: "Myanaung",
        township_mm: "မြန်အောင်",
      },
      {
        township: "Dedaye",
        township_mm: "ဒေးဒရဲ",
      },
      {
        township: "Pyapon",
        township_mm: "ဖျာပုံ",
      },
      {
        township: "Lemyethna",
        township_mm: "လေးမျက်နှာ",
      },
      {
        township: "Maubin",
        township_mm: "မအူပင်",
      },
      {
        township: "Mawlamyinegyun",
        township_mm: "မော်လမြိုင်ကျွန်း",
      },
      {
        township: "Pantanaw",
        township_mm: "ပန်းတနော်",
      },
      {
        township: "Wakema",
        township_mm: "ဝါးခယ်မ",
      },
      {
        township: "Myaungmya",
        township_mm: "မြောင်းမြ",
      },
      {
        township: "Kyaunggon",
        township_mm: "ကျောင်းကုန်း",
      },
      {
        township: "Kangyidaunt",
        township_mm: "ကန်ကြီးထောင့်",
      },
      {
        township: "Kyaiklat",
        township_mm: "ကျိုက်လတ်",
      },
      {
        township: "Zalun",
        township_mm: "ဇလွန်",
      },
      {
        township: "Kyangin",
        township_mm: "ကြံခင်း",
      },
      {
        township: "Ingapu",
        township_mm: "အင်္ဂပူ",
      },
      {
        township: "Pathein",
        township_mm: "ပုသိမ်",
      },
      {
        township: "Kyonpyaw",
        township_mm: "ကျုံပျော်",
      },
      {
        township: "Nyaungdon",
        township_mm: "ညောင်တုန်း",
      },
      {
        township: "Einme",
        township_mm: "အိမ္မဲ",
      },
      {
        township: "Hinthada",
        township_mm: "ဟင်္သာတ",
      },
      {
        township: "Danubyu",
        township_mm: "ဓနုဖြူ",
      },
      {
        township: "Yegyi",
        township_mm: "ရေကြည်",
      },
    ],
  },
  {
    name: "ကယားပြည်နယ်",
    townships: [
      {
        township: "Hpasawng",
        township_mm: "ဖားဆောင်း",
      },
      {
        township: "Hpruso",
        township_mm: "ဖရူဆို",
      },
      {
        township: "Bawlake",
        township_mm: "ဘောလခဲ",
      },
      {
        township: "Mese",
        township_mm: "မယ်စဲ့",
      },
      {
        township: "Shadaw",
        township_mm: "ရှားတော",
      },
      {
        township: "Demoso",
        township_mm: "ဒီမောဆို",
      },
      {
        township: "Loikaw",
        township_mm: "လွိုင်ကော်",
      },
    ],
  },
  {
    name: "ရခိုင်ပြည်နယ်",
    townships: [
      {
        township: "Ramree",
        township_mm: "ရမ်းဗြဲ",
      },
      {
        township: "Toungup",
        township_mm: "တောင်ကုတ်",
      },
      {
        township: "Kyaukpyu",
        township_mm: "ကျောက်ဖြူ",
      },
      {
        township: "Thandwe",
        township_mm: "သံတွဲ",
      },
      {
        township: "Munaung",
        township_mm: "မာန်အောင်",
      },
      {
        township: "Gwa",
        township_mm: "ဂွ",
      },
      {
        township: "Buthidaung",
        township_mm: "ဘူးသီးတောင်",
      },
      {
        township: "Myebon",
        township_mm: "မြေပုံ",
      },
      {
        township: "Ann",
        township_mm: "အမ်း",
      },
      {
        township: "Pauktaw",
        township_mm: "ပေါက်တော",
      },
      {
        township: "Rathedaung",
        township_mm: "ရသေ့တောင်",
      },
      {
        township: "Sittwe",
        township_mm: "စစ်တွေ",
      },
      {
        township: "Minbya",
        township_mm: "မင်းပြား",
      },
      {
        township: "Kyauktaw",
        township_mm: "ကျောက်တော်",
      },
      {
        township: "Mrauk-U",
        township_mm: "မြောက်ဦး",
      },
      {
        township: "Ponnagyun",
        township_mm: "ပုဏ္ဏားကျွန်း",
      },
      {
        township: "Maungdaw",
        township_mm: "မောင်တော",
      },
    ],
  },
  {
    name: "နေပြည်တော်",
    townships: [
      {
        township: "Pyinmana",
        township_mm: "ပျဉ်းမနား",
      },
      {
        township: "Oke Ta Ra Thi Ri",
        township_mm: "ဥတ္တရသီရိ",
      },
      {
        township: "Tatkon",
        township_mm: "တပ်ကုန်း",
      },
      {
        township: "Za Bu Thi Ri",
        township_mm: "ဇမ္ဗူသီရိ",
      },
      {
        township: "Lewe",
        township_mm: "လယ်ဝေး",
      },
      {
        township: "Zay Yar Thi Ri",
        township_mm: "ဇေယျာသီရိ",
      },
      {
        township: "Poke Ba Thi Ri",
        township_mm: "ပုဗ္ဗသီရိ",
      },
      {
        township: "Det Khi Na Thi Ri",
        township_mm: "ဒက္ခိဏသီရိ",
      },
    ],
  },
  {
    name: "စစ်ကိုင်းတိုင်းဒေသကြီး",
    townships: [
      {
        township: "Nanyun",
        township_mm: "နန်းယွန်း",
      },
      {
        township: "Myaung",
        township_mm: "မြောင်",
      },
      {
        township: "Mawlaik",
        township_mm: "မော်လိုက်",
      },
      {
        township: "Tigyaing",
        township_mm: "ထီးချိုင့်",
      },
      {
        township: "Yinmarbin",
        township_mm: "ယင်းမာပင်",
      },
      {
        township: "Pale",
        township_mm: "ပုလဲ",
      },
      {
        township: "Salingyi",
        township_mm: "ဆားလင်းကြီး",
      },
      {
        township: "Khin-U",
        township_mm: "ခင်ဦး",
      },
      {
        township: "Mingin",
        township_mm: "မင်းကင်း",
      },
      {
        township: "Hkamti",
        township_mm: "ခန္တီး",
      },
      {
        township: "Kani",
        township_mm: "ကနီ",
      },
      {
        township: "Pinlebu",
        township_mm: "ပင်လည်ဘူး",
      },
      {
        township: "Kalewa",
        township_mm: "ကလေးဝ",
      },
      {
        township: "Indaw",
        township_mm: "အင်းတော်",
      },
      {
        township: "Wuntho",
        township_mm: "ဝန်းသို",
      },
      {
        township: "Budalin",
        township_mm: "ဘုတလင်",
      },
      {
        township: "Katha",
        township_mm: "ကသာ",
      },
      {
        township: "Kyunhla",
        township_mm: "ကျွန်းလှ",
      },
      {
        township: "Tamu",
        township_mm: "တမူး",
      },
      {
        township: "Tabayin",
        township_mm: "ဒီပဲယင်း",
      },
      {
        township: "Wetlet",
        township_mm: "ဝက်လက်",
      },
      {
        township: "Ayadaw",
        township_mm: "အရာတော်",
      },
      {
        township: "Myinmu",
        township_mm: "မြင်းမူ",
      },
      {
        township: "Chaung-U",
        township_mm: "ချောင်းဦး",
      },
      {
        township: "Shwebo",
        township_mm: "ရွှေဘို",
      },
      {
        township: "Kale",
        township_mm: "ကလေး",
      },
      {
        township: "Lahe",
        township_mm: "လဟယ်",
      },
      {
        township: "Kanbalu",
        township_mm: "ကန့်ဘလူ",
      },
      {
        township: "Ye-U",
        township_mm: "ရေဦး",
      },
      {
        township: "Homalin",
        township_mm: "ဟုမ္မလင်း",
      },
      {
        township: "Paungbyin",
        township_mm: "ဖေါင်းပြင်",
      },
      {
        township: "Taze",
        township_mm: "တန့်ဆည်",
      },
      {
        township: "Lay Shi",
        township_mm: "လေရှီး",
      },
      {
        township: "Monywa",
        township_mm: "မုံရွာ",
      },
      {
        township: "Banmauk",
        township_mm: "ဗန်းမောက်",
      },
      {
        township: "Sagaing",
        township_mm: "စစ်ကိုင်း",
      },
      {
        township: "Kawlin",
        township_mm: "ကောလင်း",
      },
    ],
  },
  {
    name: "မွန်ပြည်နယ်",
    townships: [
      {
        township: "Thaton",
        township_mm: "သထုံ",
      },
      {
        township: "Ye",
        township_mm: "ရေး",
      },
      {
        township: "Chaungzon",
        township_mm: "ချောင်းဆုံ",
      },
      {
        township: "Bilin",
        township_mm: "ဘီးလင်း",
      },
      {
        township: "Mawlamyine",
        township_mm: "မော်လမြိုင်",
      },
      {
        township: "Paung",
        township_mm: "ပေါင်",
      },
      {
        township: "Kyaikto",
        township_mm: "ကျိုက်ထို",
      },
      {
        township: "Kyaikmaraw",
        township_mm: "ကျိုက္မရော",
      },
      {
        township: "Mudon",
        township_mm: "မုဒုံ",
      },
      {
        township: "Thanbyuzayat",
        township_mm: "သံဖြူဇရပ်",
      },
    ],
  },
  {
    name: "တနင်္သာရီတိုင်းဒေသကြီး",
    townships: [
      {
        township: "Kawthoung",
        township_mm: "ကော့သောင်း",
      },
      {
        township: "Kyunsu",
        township_mm: "ကျွန်းစု",
      },
      {
        township: "Launglon",
        township_mm: "လောင်းလုံ",
      },
      {
        township: "Bokpyin",
        township_mm: "ဘုတ်ပြင်း",
      },
      {
        township: "Myeik",
        township_mm: "မြိတ်",
      },
      {
        township: "Dawei",
        township_mm: "ထားဝယ်",
      },
      {
        township: "Palaw",
        township_mm: "ပုလော",
      },
      {
        township: "Yebyu",
        township_mm: "ရေဖြူ",
      },
      {
        township: "Thayetchaung",
        township_mm: "သရက်ချောင်း",
      },
      {
        township: "Tanintharyi",
        township_mm: "တနင်္သာရီ",
      },
    ],
  },
  {
    name: "ချင်းပြည်နယ်",
    townships: [
      {
        township: "Matupi",
        township_mm: "မတူပီ",
      },
      {
        township: "Hakha",
        township_mm: "ဟားခါး",
      },
      {
        township: "Tonzang",
        township_mm: "တွန်းဇံ",
      },
      {
        township: "Paletwa",
        township_mm: "ပလက်ဝ",
      },
      {
        township: "Falam",
        township_mm: "ဖလမ်း",
      },
      {
        township: "Kanpetlet",
        township_mm: "ကန်ပက်လက်",
      },
      {
        township: "Thantlang",
        township_mm: "ထန်တလန်",
      },
      {
        township: "Tedim",
        township_mm: "တီးတိန်",
      },
      {
        township: "Mindat",
        township_mm: "မင်းတပ်",
      },
    ],
  },
  {
    name: "အခြား",
    townships: [
    ],
  },
];
