import React, { Component, useMemo, useEffect, useState } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import TopNav from "./Components/TopNav/TopNav";
import { myanmarstate, nrcstatedata, nrctsdata, nrctype } from "./data";
import { sortString } from "./utilities";
import './RegForm.css';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import InputGroup from 'react-bootstrap/InputGroup';
import { useDropzone } from 'react-dropzone'
import { FcDocument } from 'react-icons/fa';

//Dropzone styling
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '26px',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'image/*': [] },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <img src='https://cdn-icons.flaticon.com/png/512/3177/premium/3177440.png?token=exp=1659414428~hmac=d30d6eb26bd64ab96ef9b598a56d9cd1' width={50}></img>
                <br></br>
                <p>ဓာတ်ပုံအားထည့်သွင်းပါ</p>
                <p className='photonotic'>ယခုဓာတ်ပုံသည် ကတ်အတွင်းထည့်သွင်းမည့်ပုံဖြစ်သည့်အတွက် သေချာစွာဂရုစိုက်ထည့်သွင်းပါ။</p>
            </div>
            <br></br>
            <p>Photo Taking Tips - For more detail on <a href="https://go.molmyanmar.org/oercphototakingtips">Our Tips</a></p>
        </div>
    );
}

function PJPV(front, props) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'image/*': [] },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <img src='https://cdn-icons-png.flaticon.com/512/72/72808.png' width={50}></img>
                <br></br>
                <p>ဓာတ်ပုံအားထည့်သွင်းပါ</p>
                <p className='photonotic'>ဓာတ်ပုံပါစာမျက်နှာ</p>
            </div>
            <br></br>
        </div>
    );
}

function MyanmarID(front, props) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'image/*': [] },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <img src='https://cdn-icons-png.flaticon.com/512/2258/2258853.png' width={50}></img>
                <br></br>
                <p>ဓာတ်ပုံအားထည့်သွင်းပါ</p>
                <p className='photonotic'>"မှတ်ပုံတင်၊ အိမ်ထောင်စုစာရင်း၊ ကျောင်းသားကတ်၊ အလုပ်သမားကတ်၊ ယာဉ်မောင်းလိုင်စင်၊ နေထိုင်ကြောင်းထောက်ခံစာ" စသည့်</p>
            </div>
            <br></br>
        </div>
    );
}

const StepProgressBar = (props) => {
    return (
        <ProgressBar percent={props.data.step} filledBackground="linear-gradient(to right, #FFE9ED, #FFC6CF)">
            <Step transition="scale">
                {({ accomplished }) => (
                    <img
                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                        width="50"
                        src="https://cdn-icons-png.flaticon.com/512/3814/3814894.png"
                    />
                )}
            </Step>
            <Step transition="scale">
                {({ accomplished }) => (
                    <img
                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                        width="50"
                        src="https://cdn-icons-png.flaticon.com/512/3814/3814330.png"
                    />
                )}
            </Step>
            <Step transition="scale">
                {({ accomplished }) => (
                    <img
                        style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                        width="50"
                        src="https://cdn-icons-png.flaticon.com/512/3814/3814579.png"
                    />
                )}
            </Step>
        </ProgressBar>
    )
}

const nric_types = nrctype.map((item, index) => {
    return (
        <option key={index} value={item.name}>
            {item.name}
        </option>
    );
});

const states = myanmarstate
    .sort((a, b) => sortString(a.name, b.name))
    .map((item, index) => {
        return (
            <option key={index} value={item.name}>
                {item.name}
            </option>
        );
    });

const nrcTspItem = nrctsdata
    .filter((item) => item.name === "၁/")
    .map((itemList) => {
        return itemList.list.map((detail) => {
            return <option value={detail.name}>{detail.name}</option>;
        });
    });

const nric_regions = nrcstatedata.map((item, index) => {
    return (
        <option key={index} value={item.name}>
            {item.name}
        </option>
    );
});

export default class RegForm extends Component {
    state = {
        "gender": "",
        "step": 0,
        "username": "",
        "usernameEng": "",
        "othername": "",
        "nrcVal": "၁/",
        "nrcText": "အဂယ",
        "nrcType": "(နိုင်)",
        "nrcNumber": 0,
        "nrcTsp": nrcTspItem,
        "year": 0,
        "month": 0,
        "date": 0,
        "fathername": "",
        "race": "",
        "state": "",
        "religion": "",
        "address": "",
    }
    render() {

        const radioHandler = (status) => {
            this.setState({ gender: status });
        };
        const goToNext = () => {
            this.setState({ step: 50 });
        };
        const goToNextStep = () => {
            this.setState({ step: 100 });
        };
        const setNrcNumber = (e) => {
            const nrcNumStr = this.state.nrcVal + this.state.nrcText + this.state.nrcType + e.target.value;
            this.setState({ nrcNumber: nrcNumStr });
        };
        const onNricRegionSelectChanged = (event) => {
            this.setState({ nrcVal: event.target.value });
            let _nricTownshipItems = nrctsdata
                .filter((item) => item.name === event.target.value)
                .map((itemList) => {
                    return itemList.list.map((detail) => {
                        return <option value={detail.name}>{detail.name}</option>;
                    });
                });
            this.setState({ nrcTsp: _nricTownshipItems });
        };
        console.log(this.state)

        return (
            <Container fluid className='p-0'>
                <TopNav />
                <Container>
                    <h3 className='oerctitle'>Overseas Employment Survey and OERC Application Form </h3>
                    <Row className='my-5 mx-3'>
                        <StepProgressBar data={this.state} />
                    </Row>
                </Container>
                {this.state.step === 0 &&
                    <Form className="container mt-3">
                        {/* <h5 className="title">အခြေခံကိုယ်ရေးအချက်အလက်များ</h5> */}
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label className='col-md-4'>အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="username" onChange={e => this.setState({ username: e.target.value })} />
                            </Col>
                            <Col md={6}>
                                <Form.Label className='col-md-4'>အမည် (အင်္ဂလိပ်ဘာသာဖြင့်) <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="username_eng" onChange={e => this.setState({ usernameEng: e.target.value })} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>အခြား အမည် <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                <Form.Control type="text" name="other_name" onChange={e => this.setState({ othername: e.target.value })} />
                            </Col>
                            <Col md={6}>
                                <Form.Label>ကျား / မ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Row>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="ကျား" onClick={(e) => radioHandler("ကျား")} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="မ" onClick={(e) => radioHandler("မ")} />
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>မှတ်ပုံတင်အမှတ် <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                <Form.Group className="mb-4 row">
                                    <Col md={2}>
                                        <div>
                                            <Form.Select alue={this.state.nrcVal} onChange={onNricRegionSelectChanged}>
                                                {nric_regions}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div>
                                            <Form.Select alue={this.state.nrcTsp} >
                                                {this.state.nrcTsp}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div>
                                            <Form.Select alue={this.state.nrcType} onChange={e => this.setState({ nrcType: e.target.value })}>
                                                {nric_types}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div>
                                            <Form.Control type="text" placeholder="000000" name="nrc-number" onChange={e => setNrcNumber(e)} />
                                        </div>
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Label>မွေးသက္ကရာဇ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Group className="mb-4 row">
                                    <Col md={4}>
                                        <div>
                                            <Form.Select value={this.state.year} onChange={e => this.setState({ year: e.target.value })}>
                                                <option>ခုနှစ်</option>
                                                <option value="2012">2012</option>
                                                <option value="2011">2011</option>
                                                <option value="2010">2010</option>
                                                <option value="2009">2009</option>
                                                <option value="2008">2008</option>
                                                <option value="2007">2007</option>
                                                <option value="2006">2006</option>
                                                <option value="2005">2005</option>
                                                <option value="2004">2004</option>
                                                <option value="2003">2003</option>
                                                <option value="2002">2002</option>
                                                <option value="2001">2001</option>
                                                <option value="2000">2000</option>
                                                <option value="1999">1999</option>
                                                <option value="1998">1998</option>
                                                <option value="1997">1997</option>
                                                <option value="1996">1996</option>
                                                <option value="1995">1995</option>
                                                <option value="1994">1994</option>
                                                <option value="1993">1993</option>
                                                <option value="1992">1992</option>
                                                <option value="1991">1991</option>
                                                <option value="1990">1990</option>
                                                <option value="1989">1989</option>
                                                <option value="1988">1988</option>
                                                <option value="1987">1987</option>
                                                <option value="1986">1986</option>
                                                <option value="1985">1985</option>
                                                <option value="1984">1984</option>
                                                <option value="1983">1983</option>
                                                <option value="1982">1982</option>
                                                <option value="1981">1981</option>
                                                <option value="1980">1980</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <Form.Select alue={this.state.month} onChange={e => this.setState({ month: e.target.value })}>
                                                <option>လ</option>
                                                <option value="ဇန်နဝါရီ">ဇန်နဝါရီ</option>
                                                <option value="ဖေဖော်ဝါရီ">ဖေဖော်ဝါရီ</option>
                                                <option value="မတ်">မတ်</option>
                                                <option value="ဧပြီ">ဧပြီ</option>
                                                <option value="မေ">မေ</option>
                                                <option value="ဇွန်">ဇွန်</option>
                                                <option value="ဇူလိုင်">ဇူလိုင်</option>
                                                <option value="သြဂုတ်">သြဂုတ်</option>
                                                <option value="စက်တင်ဘာ">စက်တင်ဘာ</option>
                                                <option value="အောက်တိုဘာ">အောက်တိုဘာ</option>
                                                <option value="နိုဝင်ဘာ">နိုဝင်ဘာ</option>
                                                <option value="ဒီဇင်ဘာ">ဒီဇင်ဘာ</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div>
                                            <Form.Select value={this.state.date} onChange={e => this.setState({ date: e.target.value })}>
                                                <option>ရက်</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                                <option value="31">31</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Form.Group>
                                {/* <Form.Control type="text" name="user_name" /> */}
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>အဘ အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="fathername" onChange={e => this.setState({ fathername: e.target.value })} />
                            </Col>
                            <Col md={6}>
                                <Form.Label>လူမျိုး <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="race" onChange={e => this.setState({ race: e.target.value })} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>မွေးဖွားရာဇာတိ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Select onChange={e => this.setState({ state: e.target.value })}>
                                    <option>‌-- ရွေးချယ်ပါ -- </option>
                                    {states}
                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <Form.Label>ဘာသာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="religion" onChange={e => this.setState({ religion: e.target.value })} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={12}>
                                <Form.Label>မြန်မာနိုင်ငံရှိနေရပ်လိပ်စာ <strong className='optionaltxt'>(Optional)</strong></Form.Label>
                                <Form.Control type="text" name="address" onChange={e => this.setState({ address: e.target.value })} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={12} className="d-flex justify-content-end">
                                <Button variant="primary" className="mt-3 btn-lg" onClick={goToNext}> Next </Button>
                            </Col>
                        </Form.Group>
                    </Form>
                }
                {this.state.step === 50 &&
                    <Form className="container mt-3">
                        {/* <h5 className="title">လိုအပ်ပါက ဆက်သွယ်နိုင်မည့်အချက်အလက်များ</h5> */}
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>ပြည်တွင်း၌ဆက်သွယ်ရမည့် ပုဂ္ဂိုလ် အမည် <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="user_name" />
                            </Col>
                            <Col md={6}>
                                <Form.Label>ပြည်တွင်း၌ဆက်သွယ်ရမည့် ပုဂ္ဂိုလ်နှင့် တော်စပ်မှု <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="user_name" />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>ပြည်တွင်း၌ဆက်သွယ်ရမည့် ပုဂ္ဂိုလ်၏ ဖုန်းနံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">+95</InputGroup.Text>
                                    <Form.Control type="text" placeholder="Enter your Phone" name="user_name" />
                                    <InputGroup.Text id="basic-addon1">Tel</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <Form.Label>ပြည်တွင်း၌ဆက်သွယ်ရမည့် ပုဂ္ဂိုလ်၏ လိပ်စာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="user_name" />
                            </Col>
                        </Form.Group>
                        {/* <h5 className="title">လက်ရှိရောက်ရှိနေမှုအချက်အလက်များ</h5> */}
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>လက်ရှိအလုပ်လုပ်ကိုင်နေသည့်နိုင်ငံ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Select aria-label="Select Your Country">
                                    <option>Select Your Country</option>
                                    <option value="1">ထိုင်းနိုင်ငံ</option>
                                    <option value="2">မလေးရှားနိုင်ငံ</option>
                                    <option value="3">ကိုရီးယားသမ္မတနိုင်ငံ</option>
                                    <option value="4">ဂျပန်နိုင်ငံ</option>
                                    <option value="5">စင်ကာပူနိုင်ငံ</option>
                                    <option value="6">ဒူဘိုင်းနိုင်ငံ</option>
                                    <option value="7">ကာတာနိုင်ငံ</option>
                                    <option value="8">တရုတ်နိုင်ငံ</option>
                                    <option value="9">အိန္ဒိယနိုင်ငံ</option>
                                    <option value="10">လာအိုနိုင်ငံ</option>
                                    <option value="11">အခြား</option>
                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <Form.Label>သက်ဆိုင်ရာနိုင်ငံသို့ စတင်ရောက်ရှိသည့် ခုနှစ်၊ လ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="date" name="user_name" />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={12}>
                                <Form.Label>ပြည်ပတွင် လက်ရှိနေရပ်လိပ်စာ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="user_name" />
                            </Col>
                        </Form.Group>
                        {/* <h5 className="title">အချက်အလက်အထောက်အထား</h5> */}
                        <Form.Group className="mb-4 row">
                            <Col md={12}>
                                <Form.Label>ကိုင်ဆောင်ထားသည့်အထောက်အထား <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Select aria-label="Select Your Passport Type">
                                    <option>Select Your Passport Type</option>
                                    <option value="1">Passport (PJ)</option>
                                    <option value="2">Passport (PV)</option>
                                    <option value="3">CI</option>
                                    <option value="4">Border Pass</option>
                                    <option value="5">အထောက်အထားမဲ့ (Undocumented)</option>
                                    <option value="6">ခိုလှုံခွင့်</option>
                                    <option value="7">သက်တမ်းကျော်လွန်နေထိုင်ခြင်း (Overstay)</option>
                                    <option value="8">အခြား</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group className='mb-4 row'>
                            <Col md={12}>
                                <Form.Label>Passport PJ နံပါတ် <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Control type="text" name="user_name" />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row copycontainer">
                            <Col md={6}>
                                <Form.Label>PJ မိတ္တူ ဓာတ်ပုံပါစာမျက်နှာ   <strong className='mandatorytxt'>*</strong></Form.Label>
                                <br></br>
                                <br></br>
                                <PJPV />
                            </Col>
                            <Col md={6}>
                                <Form.Label>PJ Visa Extension တုံးထုထားသောစာမျက်နှာ  <strong className='mandatorytxt'>*</strong></Form.Label>
                                <br></br>
                                <br></br>
                                <PJPV />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row copycontainer">
                            <Col md={6}>
                                <Form.Label>ဓာတ်ပုံပါစာမျက်နှာ <strong className='optionaltxt'>(ယခင် နိုင်ငံကူးလက်မှတ် (သို့) CI)</strong>  <strong className='mandatorytxt'>*</strong></Form.Label>
                                <br></br>
                                <br></br>
                                <PJPV />
                            </Col>
                            <Col md={6}>
                                <Form.Label>PJ Visa Extension တုံးထုထားသောစာမျက်နှာ  <strong className='mandatorytxt'>*</strong></Form.Label>
                                <br></br>
                                <br></br>
                                <PJPV />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row copycontainer">
                            <Col md={12}>
                                <Form.Label>မြန်မာပြည်မှထုတ်ပေးထားသည့် အထောက်အထားတခုခု မိတ္တူ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <br></br>
                                <br></br>
                                <MyanmarID />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>အလုပ်သမားအမျိုးအစား <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Select aria-label="Select Your Jop Type">
                                    <option>Select Your Jop Type</option>
                                    <option value="1">ပညာရှင် (Professional)</option>
                                    <option value="2">နည်းပညာရှင် (Technician)</option>
                                    <option value="3">ကျွမ်းကျင်အလုပ်သမား</option>
                                    <option value="4">အထွေထွေအလုပ်သမား</option>
                                    <option value="5">အခြား</option>
                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <Form.Label>လာရောက်သည့် အစီအစဉ် / နည်းလမ်း <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Select aria-label="Select Your Passport Type">
                                    <option>Select Your Way</option>
                                    <option value="1">မိမိအစီအစဉ်</option>
                                    <option value="2">ပြည်ပအလုပ်အကိုင်အကျိုးဆောင်လိုင်စင်ရအေဂျင်စီ၏အစီအစဉ်</option>
                                    <option value="3">ပွဲစား၏အစီအစဉ်ဖြင့်နယ်စပ်ဖြတ်ကျော်ဝင်ရောက်ခြင်း</option>
                                    <option value="4">အခြား</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={12} className="d-flex justify-content-end">
                                <Button variant="primary" className="mt-3 btn-lg" onClick={goToNextStep}> Next </Button>
                            </Col>
                        </Form.Group>
                    </Form>
                }
                {this.state.step === 100 &&
                    <Form className="container mt-3">
                        {/* <h5 className="title">အလုပ်လုပ်ကိုင်နေမှုအခြေအနေ</h5>
                        <p className='subtitle'>လိုအပ်ပါက ကျွန်ုပ်တို့ဘက်မှ ဆက်သွယ်စုံစမ်းနိုင်ရန်အတွက် တောင်းခံရခြင်းဖြစ်သည်။</p> */}
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>လက်ရှိတွင် အလုပ်လုပ်ကိုင်နေမှု ရှိ / မရှိ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Row>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="ရှိ" onClick={(e) => radioHandler(1)} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="မရှိ" onClick={(e) => radioHandler(2)} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Form.Label>လက်ရှိကြုံတွေ့နေရသည့်အခက်အခဲ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Row>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="ရှိ" onClick={(e) => radioHandler(1)} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="မရှိ" onClick={(e) => radioHandler(2)} />
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>

                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>ဆက်သွယ်ရမည့် ဖုန်းနံပါတ်<strong className='mandatorytxt'>*</strong></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control type="text" placeholder="Enter your Phone" name="user_name" />
                                    <InputGroup.Text id="basic-addon1">Tel</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <Form.Label>အီးမေးလ် လိပ်စာ<strong className='mandatorytxt'>*</strong></Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control type="text" placeholder="Enter your Email" name="user_name" />
                                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={6}>
                                <Form.Label>Digital Card (သို့) Smart Card ရယူလိုခြင်း ရှိ / မရှိ <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Row>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="ရှိ" onClick={(e) => radioHandler(1)} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Check type="radio" name="gender" label="မရှိ" onClick={(e) => radioHandler(2)} />
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            <Col md={12}>
                                <Form.Label>ဓာတ်ပုံ  <strong className='mandatorytxt'>*</strong></Form.Label>
                                <br></br>
                                <br></br>
                                <StyledDropzone />
                                {/* <p>Photo Taking Tips - For more detail on <a href="https://go.molmyanmar.org/oercphototakingtips">Our Tips</a></p> */}
                                {/* <div class="mb-3">
                                    <input class="form-control" type="file" id="formFile"></input>
                                </div> */}
                            </Col>

                        </Form.Group>
                        <Form.Group className="mb-4 row">
                            {/* <Col md={12}>
                                <Form.Label className='d-block'>GDPR Agreement  <strong className='mandatorytxt'>*</strong></Form.Label>
                                <Form.Check type="checkbox" className='checkBox' name="gender" label="I consent to having this form store my submitted information." /><label> For more detail - <a href="https://go.molmyanmar.org/oerctnc" target={"_blank"}>T&C</a> <label> / </label> <a href="https://go.molmyanmar.org/oercprivacypolicy" target={"_blank"}>Privacy Policy</a> </label>
                            </Col> */}
                            <Button variant="primary" className="mt-3">Form တင်မည်</Button>
                        </Form.Group>
                    </Form>
                }
            </Container>
        );
    }
} 