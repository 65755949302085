import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import TopNav from "./Components/TopNav/TopNav";
import { useNavigate } from "react-router-dom";
import './App.css';
import cardlogo from "./Images/oerccardsimple.svg";

function App() {
  const navigate = useNavigate();
  const goToRegistration = () => {
    navigate('/registration')
  }

  const goToTermofUse = () => {
    navigate('/termofuse')
  }

  return (
    <Container className='min-h-screen bg p-0' fluid>
      <TopNav />
      <Row className='notic' fluid>
        <Col lg={6}>
          <Image className='cardlogo' src={cardlogo} alt={cardlogo} />
        </Col>
        <Col lg={6}>
          <div className='introtxt'>
            <img className='agreementicn center' src="https://cdn-icons-png.flaticon.com/512/2664/2664064.png"></img>
            <br></br>
            <h2 className='ptitle'>ကိုယ်ရေးအချက်အလက်မူဝါဒ သဘောတူညီချက်များ</h2>
            <p className='pcontact tnc'>ကိုယ်ရေးအချက်အလက်မူဝါဒ အား‌အသေးစိတ်လေ့လာလိုပါက <a className='link' onClick={goToTermofUse}>Terms of Use</a> တွင်လေ့လာကြည့်ရှုနိုင်ပါသည်။</p><br></br>
            <h2 className='ptitle'>စစ်တမ်းကောက်ယူခြင်းနှင့် OERC ထုတ်ပေးခြင်း ရည်ရွယ်ချက်</h2>
            <p className='pcontact'>၁။	ပြည်ပတွင် ရောက်ရှိနေထိုင် လုပ်ကိုင်နေသည့် မြန်မာနိုင်ငံသားများ၏ အလုပ်အကိုင် ဆိုင်ရာ အခြေအနေကို သိရှိနိုင်ရန်၊ ကြုံတွေ့နေရသည့် အခက်အခဲများကို လျင်မြန်ထိရောက်စွာ ချိတ်ဆက်ကူညီပေးနိုင်ရန်နှင့် မြန်မာ့နွေဦးတော်လှန်ရေးအင်အားတစ်ရပ်အဖြစ် စည်းလုံးညီညွတ် စွာ ထုတ်ဖော်ပြသနိုင်ရန် ရည်ရွယ်ဆောင်ရွက်ခြင်းဖြစ်သည်။</p>
            <p className='pcontact'>၂။	ပြည်ပတွင် ရောက်ရှိနေထိုင်အလုပ်လုပ်ကိုင်နေသည့် မြန်မာနိုင်ငံသားများအားလုံးသည် စစ်တမ်းတွင် ပူးပေါင်းပါဝင်ခြင်း၊ ကတ်လျှောက်ထားခြင်း ပြုနိုင်သည်။</p>
            <p className='pcontact'>၃။	ပြည်ပအလုပ်အကိုင်ဆိုင်ရာ အသိအမှတ်ပြုသက်သေခံအဖြစ် တရားဝင်ပြည်သူ့အစိုးရ တစ်ရပ်၏ စီမံခန့်ခွဲပိုင်ခွင့်အရ ဥပဒေနှင့်အညီ ထုတ်ပေးသည့် ကတ်ဖြစ်သည်။ (နိုင်ငံသားဖြစ်မှုနှင့် သက်ဆိုင်မှု မရှိစေရ။)</p>
            {/* <p className='pcontact'>၄။ စစ်တမ်းမေးခွန်းလွှာကို ဖြေဆိုရန် အချိန် ၁၀ မိနစ်မှ ၁၅ မိနစ်ခန့် ကြာမြင့်မည်ဖြစ်ပြီးစစ်တမ်းကောက်ယူခြင်းမှ ရရှိလာသည့် အချက်အလက်များကို လုံခြုံရေးနည်းပညာအသုံးပြု၍ လျှို့ဝှက် သိမ်းဆည်းထားမည်ဖြစ်ပါသည်။</p>
          <p className='pcontact'>
            ၅။ ဖြေဆိုသည့် အချက်အလက်များကို သုတေသနနှင့် ဖွံ့ဖြိုးရေးလုပ်ငန်းများအတွက် မျှဝေရာတွင်လည်း ပုဂ္ဂိုလ်ရေး ဆိုင်ရာ အချက်အလက်များ (အမည်၊ ဖုန်းနံပါတ်၊ အီးမေးလ် စသည်) ကို ထိန်ချန်ထားမည်ဖြစ်ပါသည်။
          </p> */}

            <Button variant="outline-primary" className="agreebtn" onClick={goToRegistration}>I Agree</Button>
          </div>
        </Col>
      </Row>
    </Container >
  );
}

export default App;
