export const nrcstatedata = [
  { name: "၁/" },
  { name: "၂/" },
  { name: "၃/" },
  { name: "၄/" },
  { name: "၅/" },
  { name: "၆/" },
  { name: "၇/" },
  { name: "၈/" },
  { name: "၉/" },
  { name: "၁၀/" },
  { name: "၁၁/" },
  { name: "၁၂/" },
  { name: "၁၃/" },
  { name: "၁၄/" },
];

export const nrctsdata = [
  {
    name: "၁/",
    list: [
      { name: "ကမတ" },
      { name: "ကမန" },
      { name: "ခဖန" },
      { name: "ခဘဒ" },
      { name: "ခလဖ" },
      { name: "ဆဒန" },
      { name: "ဆပဘ" },
      { name: "ဆလန" },
      { name: "တနန" },
      { name: "နမန" },
      { name: "ပတအ" },
      { name: "ဗမန" },
      { name: "မကတ" },
      { name: "မကန" },
      { name: "မခဘ" },
      { name: "မစန" },
      { name: "မညန" },
      { name: "မပန" },
      { name: "မမန" },
      { name: "ရကန" },
      { name: "လဂန" },
      { name: "ဝမန" },
      { name: "ဟပန" },
      { name: "အဂယ" },
    ],
  },
  {
    name: "၂/",
    list: [
      { name: "ဒမဆ" },
      { name: "ဖဆန" },
      { name: "ဖရဆ" },
      { name: "ဘလခ" },
      { name: "မစန" },
      { name: "ရတန" },
      { name: "လကန" },
    ],
  },
  {
    name: "၃/",
    list: [
      { name: "ကကရ" },
      { name: "ကဆက" },
      { name: "ဖပန" },
      { name: "ဘသစ" },
      { name: "ဘအန" },
      { name: "မဝတ" },
      { name: "လဘန" },
      { name: "သတန" },
    ],
  },
  {
    name: "၄/",
    list: [
      { name: "ကပလ" },
      { name: "တဇန" },
      { name: "တတန" },
      { name: "ထတလ" },
      { name: "ပလဝ" },
      { name: "ဖလန" },
      { name: "မတန" },
      { name: "မတပ" },
      { name: "ဟခန" },
    ],
  },
  {
    name: "၅/",
    list: [
      { name: "ကနန" },
      { name: "ကဘလ" },
      { name: "ကလတ" },
      { name: "ကလထ" },
      { name: "ကလန" },
      { name: "ကလဝ" },
      { name: "ကသန" },
      { name: "ခတန" },
      { name: "ခဥတ" },
      { name: "ခဥန" },
      { name: "ငဇန" },
      { name: "စကထ" },
      { name: "စကန" },
      { name: "ဆလက" },
      { name: "တဆန" },
      { name: "တမန" },
      { name: "ထခန" },
      { name: "ဒပယ" },
      { name: "နယန" },
      { name: "ပလန" },
      { name: "ပလဘ" },
      { name: "ဖပန" },
      { name: "ဗမန" },
      { name: "ဘတလ" },
      { name: "မကန" },
      { name: "မမတ" },
      { name: "မမန" },
      { name: "မရန" },
      { name: "မလန" },
      { name: "မသန" },
      { name: "ယမပ" },
      { name: "ရဘန" },
      { name: "ရဥန" },
      { name: "လယန" },
      { name: "လဟန" },
      { name: "ဝလန" },
      { name: "ဝသန" },
      { name: "ဟမလ" },
      { name: "အတန" },
      { name: "အရတ" },
    ],
  },
  {
    name: "၆/",
    list: [
      { name: "ကစန" },
      { name: "ကသန" },
      { name: "တသရ" },
      { name: "ထဝန" },
      { name: "ပလန" },
      { name: "ဘပန" },
      { name: "မမန" },
      { name: "မအန" },
      { name: "မအရ" },
      { name: "ရဖန" },
      { name: "လလန" },
      { name: "သရခ" },
    ],
  },
  {
    name: "၇/",
    list: [
      { name: "ကကန" },
      { name: "ကတခ" },
      { name: "ကပက" },
      { name: "ကဝန" },
      { name: "ဇကန" },
      { name: "ညလပ" },
      { name: "တငန" },
      { name: "ထတပ" },
      { name: "ဒဥန" },
      { name: "နတလ" },
      { name: "ပခတ" },
      { name: "ပခန" },
      { name: "ပတတ" },
      { name: "ပတန" },
      { name: "ပမန" },
      { name: "ဖမန" },
      { name: "မညန" },
      { name: "မလန" },
      { name: "ရကန" },
      { name: "ရတန" },
      { name: "ရတရ" },
      { name: "လပတ" },
      { name: "ဝမန" },
      { name: "သကန" },
      { name: "သနပ" },
      { name: "သဝတ" },
      { name: "အတန" },
      { name: "အဖန" },
    ],
  },
  {
    name: "၈/",
    list: [
      { name: "ကမန" },
      { name: "ခမန" },
      { name: "ဂဂန" },
      { name: "ငဖန" },
      { name: "စတရ" },
      { name: "စလန" },
      { name: "ဆပဝ" },
      { name: "ဆဖန" },
      { name: "ဆမန" },
      { name: "တတက" },
      { name: "ထလန" },
      { name: "နမန" },
      { name: "ပခက" },
      { name: "ပဖန" },
      { name: "ပမန" },
      { name: "မကန" },
      { name: "မတန" },
      { name: "မထန" },
      { name: "မဘန" },
      { name: "မမန" },
      { name: "မလန" },
      { name: "မသန" },
      { name: "ရစက" },
      { name: "ရနခ" },
      { name: "သရန" },
      { name: "အလန" },
    ],
  },
  {
    name: "၉/",
    list: [
      { name: "ကဆန" },
      { name: "ကပတ" },
      { name: "ခမစ" },
      { name: "ခအဇ" },
      { name: "ငဇန" },
      { name: "ငသရ" },
      { name: "စကတ" },
      { name: "စကန" },
      { name: "ဇဗသ" },
      { name: "ဇယသ" },
      { name: "ညဥန" },
      { name: "တကန" },
      { name: "တတဥ" },
      { name: "တသန" },
      { name: "ဒခသ" },
      { name: "နထက" },
      { name: "ပကခ" },
      { name: "ပဗသ" },
      { name: "ပဘန" },
      { name: "ပမန" },
      { name: "ပသက" },
      { name: "ပဥလ" },
      { name: "မကန" },
      { name: "မခန" },
      { name: "မတရ" },
      { name: "မထလ" },
      { name: "မနတ" },
      { name: "မနမ" },
      { name: "မမန" },
      { name: "မရတ" },
      { name: "မရမ" },
      { name: "မလန" },
      { name: "မသန" },
      { name: "မဟမ" },
      { name: "မအဇ" },
      { name: "ရမသ" },
      { name: "လဝန" },
      { name: "ဝတန" },
      { name: "သစန" },
      { name: "သပက" },
      { name: "အမဇ" },
      { name: "အမရ" },
      { name: "ဥတသ" },
    ],
  },
  {
    name: "၁၀/",
    list: [
      { name: "ကထန" },
      { name: "ကမရ" },
      { name: "ခဆန" },
      { name: "ပမန" },
      { name: "ဘလန" },
      { name: "မဒန" },
      { name: "မလန" },
      { name: "ရမန" },
      { name: "လမန" },
      { name: "သထန" },
      { name: "သဖရ" },
    ],
  },
  {
    name: "၁၁/",
    list: [
      { name: "ကတန" },
      { name: "ကဖန" },
      { name: "ဂမန" },
      { name: "စတန" },
      { name: "တကန" },
      { name: "ပဏက" },
      { name: "ပတန" },
      { name: "ဘသတ" },
      { name: "မတန" },
      { name: "မပတ" },
      { name: "မပန" },
      { name: "မအန" },
      { name: "မဥန" },
      { name: "ရဗန" },
      { name: "ရသတ" },
      { name: "သတန" },
      { name: "အမန" },
    ],
  },
  {
    name: "၁၂/",
    list: [
      { name: "ကကက" },
      { name: "ကခက" },
      { name: "ကတတ" },
      { name: "ကတန" },
      { name: "ကမတ" },
      { name: "ကမန" },
      { name: "ကမရ" },
      { name: "ခရန" },
      { name: "စခန" },
      { name: "ဆကခ" },
      { name: "ဆကန" },
      { name: "ဥကမ" },
      { name: "တကန" },
      { name: "တတထ" },
      { name: "တတန" },
      { name: "တမန" },
      { name: "ထတပ" },
      { name: "ဒဂဆ" },
      { name: "ဒဂတ" },
      { name: "ဒဂန" },
      { name: "ဒဂမ" },
      { name: "ဒဂရ" },
      { name: "ဒပန" },
      { name: "ဒလန" },
      { name: "ပဇတ" },
      { name: "ပဘတ" },
      { name: "ဗတထ" },
      { name: "ဗဟန" },
      { name: "မဂတ" },
      { name: "မဂဒ" },
      { name: "မဘန" },
      { name: "မရက" },
      { name: "ရကန" },
      { name: "ရပသ" },
      { name: "လကန" },
      { name: "လမတ" },
      { name: "လမန" },
      { name: "လသန" },
      { name: "လသယ" },
      { name: "သကတ" },
      { name: "သခန" },
      { name: "သဃက" },
      { name: "သလန" },
      { name: "အစန" },
      { name: "အလန" },
      { name: "ဥကတ" },
    ],
  },
  {
    name: "၁၃/",
    list: [
      { name: "ကကန" },
      { name: "ကခန" },
      { name: "ကတန" },
      { name: "ကမန" },
      { name: "ကလတ" },
      { name: "ကလန" },
      { name: "ကသန" },
      { name: "ကဟန" },
      { name: "ခလန" },
      { name: "ဆဆန" },
      { name: "ညရန" },
      { name: "တကန" },
      { name: "တခန" },
      { name: "တခလ" },
      { name: "တယန" },
      { name: "တလန" },
      { name: "နခတ" },
      { name: "နခန" },
      { name: "နစန" },
      { name: "နဆန" },
      { name: "နတရ" },
      { name: "နဖန" },
      { name: "နမတ" },
      { name: "ပတယ" },
      { name: "ပယန" },
      { name: "ပလတ" },
      { name: "ပလန" },
      { name: "ပဝန" },
      { name: "ဖခန" },
      { name: "မကတ" },
      { name: "မကန" },
      { name: "မခန" },
      { name: "မငန" },
      { name: "မဆက" },
      { name: "မဆတ" },
      { name: "မဆန" },
      { name: "မတန" },
      { name: "မနန" },
      { name: "မပတ" },
      { name: "မပန" },
      { name: "မဖတ" },
      { name: "မဖန" },
      { name: "မဘန" },
      { name: "မမတ" },
      { name: "မမန" },
      { name: "မယတ" },
      { name: "မယန" },
      { name: "မရတ" },
      { name: "မရန" },
      { name: "မလန" },
      { name: "ရငန" },
      { name: "ရစန" },
      { name: "လကန" },
      { name: "လခတ" },
      { name: "လခန" },
      { name: "လရန" },
      { name: "လလန" },
      { name: "သနန" },
      { name: "သပန" },
      { name: "ဟပတ" },
      { name: "ဟပန" },
    ],
  },
  {
    name: "၁၄/",
    list: [
      { name: "ကကထ" },
      { name: "ကကန" },
      { name: "ကခန" },
      { name: "ကပန" },
      { name: "ကလန" },
      { name: "ငဆန" },
      { name: "ငပတ" },
      { name: "ငရက" },
      { name: "ငသခ" },
      { name: "ဇလန" },
      { name: "ညတန" },
      { name: "ဒဒရ" },
      { name: "ဓနဖ" },
      { name: "ပစလ" },
      { name: "ပတန" },
      { name: "ပသန" },
      { name: "ပသရ" },
      { name: "ဖပန" },
      { name: "ဘကလ" },
      { name: "မမက" },
      { name: "မမန" },
      { name: "မအန" },
      { name: "မအပ" },
      { name: "ရကန" },
      { name: "လပတ" },
      { name: "လမန" },
      { name: "ဝခမ" },
      { name: "သပန" },
      { name: "ဟကက" },
      { name: "ဟသတ" },
      { name: "အဂပ" },
      { name: "အမတ" },
      { name: "အမန" },
    ],
  },
];

export const nrctype = [
  { name: "(နိုင်)" },
  { name: "(ဧည့်)" },
  { name: "(ပြု)" },
  { name: "(စ)" },
  { name: "(သ)" },
  { name: "(သီ)" },
];
