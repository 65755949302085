import React, { Component } from 'react';
import { Container, Form, Row, Col, Button, Navbar } from 'react-bootstrap'; 

export default class TopNav extends Component { 
    render() {
        return (
            <>
                <Row className='nugstart' fluid>
                    <div>
                        <img src="https://assets-mol.nugmyanmar.org/images/2021/12/state_logo_flat-01.svg" alt="Myanmar State Emblem" width="30" height="25"></img>
                        <a className='nug hypernug' href="https://nugmyanmar.org" target="_blank" rel="noopener"><span>An Official Website of the National Unity Government</span></a>
                        <a className='nug' data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            How to identify?
                        </a>
                    </div>
                    <div className="collapse topbar2" id="collapseExample">
                        <div>
                            <Row>
                                <Col md={6}>
                                    <img src="https://d33wubrfki0l68.cloudfront.net/2ec2122b2010672b9cbb6efb5138048d8aff4a09/5d1ab/assets/img/government_building.svg" class="banner-icon"></img>
                                    <div>
                                        <strong className='subbannertitle'> NUG Official website links usually end with .nugmyanmar.org</strong>
                                        <p className='nugctext'>Ministry of Labour communicate via <strong>.nugmyanmar.org</strong> or <strong>.molmyanmar.org </strong>websites. <a href="https://go.molmyanmar.org/trusted-sites" class="banner-content-text bp-masthead-button-link bp-masthead-button-text" target="_blank" rel="noreferrer">Trusted websites</a></p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <img src="https://d33wubrfki0l68.cloudfront.net/aecdf1b88c0edfa72e7fef099f28e93b80c8336a/8c6a1/assets/img/lock.svg" class="banner-icon"></img>
                                    <div>
                                        <strong className='subbannertitle'> Secure websites use HTTPS</strong>

                                        <p className='nugctext'>Look for a <strong> lock </strong>(<img src="https://d33wubrfki0l68.cloudfront.net/aecdf1b88c0edfa72e7fef099f28e93b80c8336a/8c6a1/assets/img/lock.svg" class="inline-banner-icon"></img>) or https:// as an added precaution. Share sensitive information only on official, secure websites.</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>   
            </>    
        );
    }
} 